//TODO https://jira.healthcareit.net/browse/DCP2-7022
import React, { useCallback, useEffect, useState } from 'react';
import LoadingPage from './LoadingSpinner';
import { useAppDispatch } from '../reduxStore/reduxHooks';
import { getUserLegacyPermissions, getUserPermissions } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { RouteNames } from '../Routes/Navigation';
import { setUserPermissions } from '../reduxStore/userPermissionsSlice';
import { IApiError } from '../api/errorHandling/interfaces';
import { IUserPermission } from '../api/user/interface';

interface IProps {
  children: React.ReactNode;
}

export function deduplicatePermissions(
  legacyPermissions: IUserPermission[],
  permissions: IUserPermission[]
): IUserPermission[] {
  //we need to filter out any permission where the resource type and at least one permission matches to the go-forward permission structure.
  //NOTE: if 'permissions' has EraSearch.Read and EraSearch.Create and legacyPermissions has EraSearch.Read, we can take that out because it is covered in the 'permissions' already.
  //'legacyPermissions' will never have more than 1 item in the resourcePermissions array.
  return legacyPermissions.filter(
    (p) =>
      !permissions.some(
        (a) => a.resourceType === p.resourceType && a.resourcePermissions.some((x) => p.resourcePermissions.includes(x))
      )
  );
}

const AuthContainer: React.FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();

  const [hadError, setHadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleApiError = (err: any) => {
    const apiError = err as IApiError;

    if (apiError?.status?.toString() !== '403') setHadError(true); //they can still go to the Home page when 403, since they may not yet be onboarded.
  };

  const handleFetch = useCallback(async () => {
    setIsLoading(true);

    //TODO: US8033381: Remove Obsolete Components of Legacy Authorization
    let mappedPermissions: IUserPermission[] = [];
    try {
      mappedPermissions = await getUserLegacyPermissions(dispatch);
    } catch (err) {
      handleApiError(err);
      setIsLoading(false);
    }

    try {
      const permissions: IUserPermission[] = (await getUserPermissions(dispatch)).permissions;

      //WE WON'T NEED TO DEDUPE ONCE WE REMOVE user/me
      const deDupedPermissions: IUserPermission[] = deduplicatePermissions(mappedPermissions, permissions);

      mappedPermissions = deDupedPermissions.concat(permissions);
    } catch (err) {
      handleApiError(err);
      setIsLoading(false);
    }

    dispatch(setUserPermissions(mappedPermissions));
    setIsLoading(false);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      handleFetch().catch(handleApiError);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && hadError) {
      navigate(RouteNames.Error);
    }
  }, [isLoading, hadError]);

  return isLoading ? <LoadingPage /> : <div>{children} </div>;
};

export default AuthContainer;
