import React from "react";
import { Row, Col, Input, Text, SelectDropdown } from "@uicl/ui-core/dist";
import { IEligibilityInquiryRequest } from "../../../api/eligibilityInquiry/interface";
import { IGenderProps } from "../interface";

interface IProps {
    eligibilityInquiryRequestBody: IEligibilityInquiryRequest;
    handleInputChange: (e: any) => void;
    genderOptions: IGenderProps[];
    initialGenderValue: IGenderProps;
}

const DependentDetailsComponent: React.FC<IProps> = ({ eligibilityInquiryRequestBody, handleInputChange, genderOptions, initialGenderValue }) => {
    return (
        <>
            <Row>
                <Col sm={6}>
                    <Text variant="largeBold" className="eligibility-inquiry-header">Dependent Details</Text>
                    <Text variant="xSmall" className="eligibility-inquiry-header">Dependent fields marked required * are only needed if you are searching for a dependent.
                        If you are not searching for a dependent, please leave these fields blank.
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Input
                        aria-label="dependentFirstName"
                        dataTestId="test-dependentFirstName"
                        domID="dependentFirstName"
                        name="dependentDetails.firstName"
                        label="First Name *"
                        placeholder="First Name"
                        size="medium"
                        maxLength={35}
                        errorMessage="Invalid First Name"
                        regex="^[^\t\n]{0,35}$"
                        value={eligibilityInquiryRequestBody?.dependentDetails?.firstName}
                        onChange={handleInputChange} />
                </Col>
                <Col sm={3}>
                    <Input
                        aria-label="dependentLastName"
                        dataTestId="test-dependentLastName"
                        domID="dependentLastName"
                        name="dependentDetails.lastName"
                        label="Last Name *"
                        placeholder="Last Name"
                        size="medium"
                        maxLength={60}
                        errorMessage="Invalid Last Name"
                        regex="^[^\t\n]{0,60}$"
                        value={eligibilityInquiryRequestBody?.dependentDetails?.lastName}
                        onChange={handleInputChange} />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Input
                        domID="dependentDob"
                        aria-label="dependentDob"
                        dataTestId="test-dependentDob"
                        name="dependentDetails.dateOfBirth"
                        label="Date of Birth *"
                        size="medium"
                        mask="99/99/9999"
                        placeholder="MM/DD/YYYY"
                        regex="^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$"
                        errorMessage="Invalid Date of Birth"
                        value={eligibilityInquiryRequestBody?.dependentDetails?.dateOfBirth}
                        onChange={handleInputChange} />
                </Col>
                <Col sm={1}>
                    <SelectDropdown
                        domID="dependentGender"
                        aria-label="dependentGender"
                        dataTestId="test-dependentGender"
                        label="Gender"
                        size="medium"
                        isSearchable={false}
                        isClearable={false}
                        initialValue={initialGenderValue}
                        options={genderOptions}
                        onChange={(e) => handleInputChange({ target: { name: 'dependentDetails.gender', value: e.value } })} />
                </Col>
                <Col sm={3}>
                    <Input
                        aria-label="dependentGroupNumber"
                        dataTestId="test-dependentGroupNumber"
                        domID="dependentGroupNumber"
                        name="dependentDetails.groupNumber"
                        label="Group Number"
                        placeholder="Group Number"
                        size="medium"
                        errorMessage="Invalid Group Number"
                        regex="^[^\t\n]{0,50}$"
                        value={eligibilityInquiryRequestBody?.dependentDetails?.groupNumber}
                        onChange={handleInputChange} />
                </Col>
            </Row>
        </>
    );
};


export default DependentDetailsComponent;