import { IPermissionResourceType } from '../../reduxStore/userPermissionsSlice/interfaces';
import { INavigationItem, INavigationSectionItem } from './interfaces';

export type CheckPermission = (permission: IPermissionResourceType[]) => boolean;

export function addNavItem(
  menuItems: Array<Array<INavigationItem>>,
  item: INavigationItem,
  permission: IPermissionResourceType[],
  checkPermissionFunc: CheckPermission
): Array<any> {
  if (checkPermissionFunc(permission)) {
    return [...menuItems.filter((a) => a.every((x) => x.id !== item.id)), [item]];
  }
  return menuItems;
}

export function addSectionItem(
  sectionItems: INavigationSectionItem[],
  item: INavigationSectionItem,
  permission: IPermissionResourceType[],
  checkPermissionFunc: CheckPermission
): Array<any> {
  if (checkPermissionFunc(permission)) {
    return [...sectionItems.filter((a) => a.id !== item.id), item];
  }

  return sectionItems;
}
