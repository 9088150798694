import { useAppSelector } from '../reduxStore/reduxHooks';
import { IPermissionResourceType } from '../reduxStore/userPermissionsSlice/interfaces';

export function useUserPermission() {
  const userPermissions = useAppSelector((state) => state.userPermissionsSlice.permissions) || [];
  return {
    userPermissions: userPermissions,
    checkUserPermission: (permissionResourceType: IPermissionResourceType[]) => {
      return userPermissions.some(
        (userPermission) =>
          permissionResourceType.some((y)=> y.resourceType.toLowerCase() === userPermission.resourceType.toLowerCase()) &&
            userPermission.resourcePermissions.some(
              (rp) => permissionResourceType.some((z)=> rp?.toLowerCase() === z.resourcePermission.toLowerCase())
            )
        );
    },
  };
}
