import React, { useEffect, useState } from 'react';
import integrations from '../../integrations';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/optum-logo-ora-rgb.svg';
import { Masthead, Text, Modal, ModalBody, ButtonGroup, Button } from '@uicl/ui-core/dist';
import { DisplayText, title } from '../../utils/constants';
import { Chart, Home, Info, Warning, DiamondDetail } from '@uicl/ui-core/dist';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import {
  archiveAllNotifications,
  archiveNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markNotificationAsShown,
} from '../../reduxStore/notificationSlice';
import { IconType, INotificationState } from '../../reduxStore/notificationSlice/interfaces';
import NotificationBodyComponent from '../Notifications/NotificationBodyComponent';
import { INotification } from '../Notifications/interfaces';
import { REACT_APP_STAGE } from '../../config';

import { useAuth } from 'react-oidc-context';
import { SignoutRedirectArgs } from 'oidc-client-ts';
import { RouteNames } from '../../Routes/Navigation';
import { addNavItem, addSectionItem } from './functions';
import { INavigationSectionItem } from './interfaces';
import { permissionResourceTypes } from '../../reduxStore/userPermissionsSlice/interfaces';
import { useUserPermission } from '../../hooks/useUserPermission';

interface HeaderState {
  profileImage: any;
  imageData: any;
  brandImage: boolean;
  accountId: number | undefined;
  userName: string | undefined;
  showTool: boolean;
  isOpen: boolean;
  logoutmessege: string;
}

const signoutRedirectArgs: SignoutRedirectArgs = {
  extraQueryParams: {
    post_logout_redirect_uri: integrations.sentinel.post_logout_redirect_uri,
  },
};

const Header = () => {
  const [headerState, setHeaderState] = useState<HeaderState>({
    profileImage: '',
    imageData: '',
    brandImage: false,
    accountId: undefined,
    userName: '',
    showTool: false,
    isOpen: false,
    logoutmessege: 'Are you sure want to Logout?',
  });

  const navigate = useNavigate();
  const auth = useAuth();
  const { checkUserPermission } = useUserPermission();

  const loginRedirect = () => {
    navigate(RouteNames.Login);
  };

  const logoutRedirect = async () => {
    await auth.signoutRedirect(signoutRedirectArgs);
  };

  const toggleLogoutModal = () => {
    setHeaderState({
      ...headerState,
      isOpen: !headerState.isOpen,
    });
  };

  const getNavMenuItems = () => {
    // NOTE: removing this first entry causes the menu to break when using Sections
    let navMenuItems = [[{ id: 1, label: 'Home', path: RouteNames.Home, icon: Home }]];

    //US7525027 : Remove as part of this story
    navMenuItems = addNavItem(
      navMenuItems,
      {
        id: 10,
        label: 'Claims',
        path: RouteNames.Claims,
        icon: Chart,
      },
      [permissionResourceTypes.searchProcessedClaims],
      checkUserPermission
    );

    navMenuItems = addNavItem(
      navMenuItems,
      {
        id: 2,
        label: 'Claim Attachments',
        path: RouteNames.ClaimAttachmentSearch,
        icon: Chart,
      },
      [permissionResourceTypes.searchAttachments],
      checkUserPermission
    );

    //TODO ERA search logic goes here for authorized or unauthorized user
    navMenuItems = addNavItem(
      navMenuItems,
      { id: 3, label: 'ERA / EFT Search', path: RouteNames.EraSearch, icon: DiamondDetail },
      [permissionResourceTypes.searchEra],
      checkUserPermission
    );

    const isProduction = REACT_APP_STAGE?.toLowerCase() === 'prod';

    let sectionItems: INavigationSectionItem[] = [];

    if (!isProduction) {
      sectionItems = addSectionItem(
        sectionItems,
        { id: 7, label: 'Inquiry', path: RouteNames.EligibilityInquiry, icon: Chart },
        [permissionResourceTypes.submitEligibilityInquiry,permissionResourceTypes.readEligibilityInquiry],
        checkUserPermission
      );
    }

    sectionItems = addSectionItem(
      sectionItems,
      { id: 4, label: 'Historical Transactions', path: RouteNames.HistoricalTransactions, icon: Chart },
      [permissionResourceTypes.searchHistoricalEligibility],
      checkUserPermission
    );

    if (
      checkUserPermission([permissionResourceTypes.submitEligibilityInquiry]) ||
      checkUserPermission([permissionResourceTypes.searchHistoricalEligibility]) ||
      checkUserPermission([permissionResourceTypes.readEligibilityInquiry])
    )
      navMenuItems = [
        ...navMenuItems,
        {
          // @ts-ignore
          title: 'Eligibility',
          sectionItems: sectionItems,
        },
      ];

    return navMenuItems;
  };

  const navMenuItems = getNavMenuItems();

  const onNavMenuItemSelectHandler = (event: any, navMenuItem: any) => {
    navigate(navMenuItem.activeItem.path);
  };

  const handleAdminNavigation = () => {
    const supportActions = {
      title: 'Support',
      sectionItems: [
        {
          label: 'Onboard Account',
          id: 6,
          path: RouteNames.Onboarding,
        },
      ],
    };
    const actions = [];

    if (checkUserPermission([permissionResourceTypes.onboardAccount])) {
      actions.push(supportActions);
    }
    return actions;
  };

  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state?.notificationSlice?.notifications);

  const [mappedNotifs, setMappedNotifs] = useState([] as INotification[] | undefined);
  const [popupNotifications, setPopupNotifications] = useState([] as INotificationState[] | undefined);

  const mapNotification = (notificationItem: INotificationState): INotification => {
    const icon = notificationItem.icon === IconType.Warning ? () => <Warning fillColor={'red'} /> : Info;
    return {
      ...notificationItem,
      icon: icon,
      description: <NotificationBodyComponent notification={notificationItem} />,
    };
  };

  useEffect(() => {
    const tempMappedNotifs = notifications.map(mapNotification);
    setMappedNotifs(tempMappedNotifs);
    setPopupNotifications(notifications.filter((n) => !n.isShown && !n.isRead).map(mapNotification));

    //cleanup on component unmount
    return () => {
      setMappedNotifs(undefined);
      setPopupNotifications(undefined);
    };
  }, [notifications]);

  const onMarkAllAsReadHandler = () => {
    dispatch(markAllNotificationsAsRead());
  };

  const onMarkAsReadHandler = (notificationId: string | number) => {
    dispatch(markNotificationAsRead({ notificationId }));
  };

  const onArchiveHandler = (notificationId: string | number) => {
    dispatch(archiveNotification({ notificationId }));
  };

  const onArchiveAllHandler = () => {
    dispatch(archiveAllNotifications());
  };

  const onPopupNotificationFinishHandler = (finishedNotification: any) => {
    if (!finishedNotification.isShown) {
      dispatch(markNotificationAsShown({ notificationId: finishedNotification.id }));
    }
  };

  //NOTE: Masthead ignores custom styling somehow. Styled components approach did not override component styles like expected. Height could be 55px instead of 48px
  return (
    <div>
      <div className="no-print">
        <Masthead
          domID="header-test-id"
          dataTestId="header-test-id"
          currentProductName={`${title.Dental} ${title.Connect}`}
          productNameOneLine={true}
          logoHeight={30}
          logoRedirect={RouteNames.Home}
          onLogOutSelect={toggleLogoutModal}
          avatarImage={headerState.profileImage}
          integrations={integrations}
          isStandaloneLogin={false}
          onStandaloneLoginSelect={loginRedirect}
          supportMenuConfig={{
            shouldShow: false,
          }}
          onAvatarMenuSelect={(event: any, selection: any) => {
            navigate(selection.activeItem.path!.toString());
          }}
          // @ts-ignore
          customUserMenuItems={handleAdminNavigation()}
          // onProfileSelect={loginRedirect}
          userFirstName={auth?.user?.profile?.given_name}
          userLastName={auth?.user?.profile?.family_name}
          userEmail={auth?.user?.profile?.email}
          logoSrc={headerState.brandImage ? headerState.imageData : Logo}
          navMenuDisplay="sidebarOnly"
          navMenuItems={navMenuItems}
          onNavMenuSelect={onNavMenuItemSelectHandler}
          initialShowSidebar={true}
          showNotifications
          onPopupNotificationFinish={onPopupNotificationFinishHandler}
          // @ts-ignore
          popupNotifications={popupNotifications}
          popupNotificationTime={3000}
          popupNotificationAnimationTime={300}
          notifications={mappedNotifs}
          markAllAsRead={onMarkAllAsReadHandler}
          markAsRead={onMarkAsReadHandler}
          archive={onArchiveHandler}
          archiveAll={onArchiveAllHandler}
        />
        {/* this is the logout modal that pops up when you click Logout. might be something here with the ciam Logout (wasn't even wired up to start) */}
        {headerState.isOpen && (
          <Modal isOpen={headerState.isOpen}>
            {/*@ts-ignore*/}
            <ModalBody>
              <div className="p-4" data-testid="logout-message-testid">
                <Text className="primaryBlueColor headerFont pb-3">{headerState.logoutmessege}</Text>
                <div className="pt-5">
                  <div className="d-flex justify-content-end">
                    <div data-testid="logout-container">
                      {/* @ts-ignore className 'unsupported'*/}
                      <ButtonGroup className="mx-2">
                        <Button
                          type="button"
                          className="submit-btn acceptButton"
                          name={DisplayText.Ok}
                          onClick={async () => await logoutRedirect()}
                          dataTestId="ok-button"
                        />
                        <Button
                          className="submit-btn acceptButton"
                          type="button"
                          onClick={toggleLogoutModal}
                          name={DisplayText.Cancel}
                          dataTestId="cancel-button"
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default Header;
