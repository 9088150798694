import { IUserPermission, UserActionTypes } from './interface';
import { PermissionTypes, ResourceTypes } from '../../reduxStore/userPermissionsSlice/interfaces';

//TODO: US8033381: Remove Obsolete Components of Legacy Authorizations
export function mapActionsToPermissions(userInfo: {
  allowableActions: { actionName: UserActionTypes }[];
}): IUserPermission[] {
  if (!userInfo?.allowableActions) return [];

  return userInfo.allowableActions
    .map((a) => {
      const action: UserActionTypes = a.actionName;

      if (action === UserActionTypes.SEARCHPROCESSEDCLAIMS)
        return {
          resourceType: ResourceTypes.ProcessedClaimSearch,
          resourcePermissions: [PermissionTypes.Read],
        } as IUserPermission;

      if (action === UserActionTypes.GETPROCESSEDCLAIMDETAIL)
        return {
          resourceType: ResourceTypes.ProcessedClaimDetails,
          resourcePermissions: [PermissionTypes.Read],
        } as IUserPermission;

      if (
        action === UserActionTypes.SEARCHERA ||
        action === UserActionTypes.SEARCHERADETAILS ||
        action === UserActionTypes.GETERAFILEDATA
      )
        return {
          resourceType: ResourceTypes.EraSearch,
          resourcePermissions: [PermissionTypes.Read],
        } as IUserPermission;

      if (action === UserActionTypes.SUBMITELIGIBILITYINQUIRY)
        return {
          resourceType: ResourceTypes.EligibilityInquiry,
          resourcePermissions: [PermissionTypes.Create],
        };

      if (action === UserActionTypes.SEARCHELIGIBILITY)
        return {
          resourceType: ResourceTypes.HistoricalEligibilitySearch,
          resourcePermissions: [PermissionTypes.Read],
        } as IUserPermission;

      if (action === UserActionTypes.GETELIGIBILITYBYID || action === UserActionTypes.GETELIGIBILITYFILEDATA)
        return {
          resourceType: ResourceTypes.HistoricalEligibilityDetail,
          resourcePermissions: [PermissionTypes.Read],
        } as IUserPermission;

      if (action === UserActionTypes.ONBOARDACCOUNT)
        return { resourceType: ResourceTypes.OnboardAccount, resourcePermissions: [PermissionTypes.Create] };

      //ugly, but doing this align types better and no reason to throw if the action is one we don't care about
      return { resourceType: '', resourcePermissions: [] } as IUserPermission;
    })
    .filter((x) => !!x.resourceType);
}
