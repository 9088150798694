import React, { Dispatch, SetStateAction } from 'react';
import { Button, ButtonGroup, Input, Text, SelectDropdown } from '@uicl/ui-core/dist';
import { DrawerBox, DrawerContatiner, DrawerContent, DrawerDisplayContent } from '../../../components/HistoricalTransactions/styles';
import { IHistoricalTransactionsSearchRequestFrontBody } from 'api/HistoricalTransactions/interfaces';
interface InitialOptions {
  label: string;
  value: string;
  dataTestId: string;
  id: string;
}
interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  historicalTransactionsSearchRequestBody: IHistoricalTransactionsSearchRequestFrontBody;
  handleOnReset: () => void;
  handleOnChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  initialErrorOption: InitialOptions | null;
  currentErrorOption: InitialOptions[];
  setCurrentErrorOption: Dispatch<SetStateAction<string>>;
}

const HistoricalSearchFilterModal: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  onSave,
  historicalTransactionsSearchRequestBody,
  handleOnReset,
  handleOnChange,
  initialErrorOption,
  currentErrorOption,
  setCurrentErrorOption,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <DrawerBox data-testid="drawerContainer" id="drawerContainer">
      <DrawerContatiner>
        <DrawerContent percentageView={true} containerWidth={'70vw'}>
          <Text variant="large">Search Filters</Text>
          <div style={{ marginTop: 10, borderBottom: '2px solid #e0e0e0' }}></div>
          <div data-testid="historical-filter-page-testid" style={{ marginLeft: 10, marginTop: 13 }}>
            <div style={{ marginLeft: 80, marginTop: 10, display: 'flex' }}>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="transactionId"
                  domID="transactionId-id"
                  placeholder="Transaction ID "
                  label="Transaction ID  "
                  size="medium"
                  name="transactionId"
                  maxLength={9}
                  value={historicalTransactionsSearchRequestBody.transactionId}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="payerId"
                  domID="payerId-id"
                  placeholder="Payer ID "
                  label="Payer ID"
                  size="medium"
                  name="payerId"
                  maxLength={5}
                  value={historicalTransactionsSearchRequestBody.payerId}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
            </div>

            <div style={{ marginLeft: 80, marginTop: 10, display: 'flex' }}>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="vendorIds"
                  domID="vendorId-id"
                  placeholder="Vendor ID"
                  label="Vendor ID"
                  size="medium"
                  name="vendorIds"
                  maxLength={3}
                  value={historicalTransactionsSearchRequestBody.vendorIds}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="taxId"
                  domID="taxId-id"
                  placeholder="Tax ID"
                  label="Tax ID"
                  size="medium"
                  name="taxId"
                  maxLength={9}
                  value={historicalTransactionsSearchRequestBody.taxId}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
            </div>
            <div style={{ marginLeft: 80, marginTop: 10, display: 'flex' }}>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="insuredId"
                  domID="insureId-id"
                  placeholder="Insured ID"
                  label="Insured ID"
                  size="medium"
                  name="insuredId"
                  maxLength={80}
                  value={historicalTransactionsSearchRequestBody.insuredId}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="patientLastName"
                  domID="pLName-id"
                  placeholder="Patient Last Name"
                  label="Patient Last Name"
                  size="medium"
                  name="patientLastName"
                  maxLength={60}
                  value={historicalTransactionsSearchRequestBody.patientLastName}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
            </div>
            <div style={{ marginLeft: 80, marginTop: 10, display: 'flex' }}>
              <div style={{ marginLeft: 15, display: 'flex' }}>
                <Input
                  aria-label="ARIA Label"
                  autoComplete="off"
                  dataTestId="patientFirstName"
                  domID="pFName-id"
                  placeholder="Patient First Name"
                  label="Patient First Name"
                  size="medium"
                  name="patientFirstName"
                  maxLength={35}
                  value={historicalTransactionsSearchRequestBody.patientFirstName}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
              <div style={{ marginLeft: 15, marginTop: 7, display: 'flex' }}>
                <SelectDropdown
                  ariaLabel="ARIA label"
                  dataTestId="test-select_errorsonly"
                  domID="test-id_erors"
                  size="small"
                  label="Errors Only"
                  initialValue={initialErrorOption!}
                  onChange={(opt: any) => {
                    const value = opt ? opt.value : null;
                    setCurrentErrorOption(value as string);
                    historicalTransactionsSearchRequestBody.isErrorsOnly = value == '1' ? true : false;
                  }}
                  menuWidth={100}
                  options={currentErrorOption}
                />
              </div>
            </div>

            <div style={{ float: 'right', display: 'flex', marginTop: 15 }}>
              {/*@ts-ignore*/}
              <ButtonGroup>
                <Button
                  buttonType="diminished"
                  dataTestId="button-cancel"
                  name="Cancel"
                  size="medium"
                  onClick={onClose}
                  domID="id_cancel"
                />
                <Button
                  dataTestId="button-reset"
                  name="Reset"
                  buttonType="standard"
                  size="small"
                  type="reset"
                  onClick={handleOnReset}
                  domID="id_reset"
                />
                <Button
                  buttonType="emphasized"
                  name="Apply"
                  domID="id_apply"
                  dataTestId="button-apply"
                  size="large"
                  onClick={onClose}
                />
              </ButtonGroup>
            </div>
          </div>
          <DrawerDisplayContent></DrawerDisplayContent>
        </DrawerContent>
      </DrawerContatiner>
    </DrawerBox>
  );
};

export default HistoricalSearchFilterModal;
