import React from "react";
import { Row, Col, Input, Text, RadioGroup } from "@uicl/ui-core/dist";
import { IEligibilityInquiryRequest } from "../../../api/eligibilityInquiry/interface";

interface IProps {
    eligibilityInquiryRequestBody: IEligibilityInquiryRequest;
    handleInputChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    isOrganization: boolean;
    setIsOrganization: (value: boolean) => void;
    isNpi : boolean;
    setIsNpi : (value: boolean) => void;
}

const ProviderDetailsComponent: React.FC<IProps> = ({ eligibilityInquiryRequestBody, handleInputChange, isOrganization, setIsOrganization, isNpi , setIsNpi }) => {
    return (
        <>
            <Row>
                <Col sm={3}>
                    <Text variant="largeBold" className="eligibility-inquiry-header">Provider</Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <RadioGroup
                        dataTestId="test-organizationOrProvider"
                        checkedItem={{ id: isOrganization ? '1' : '2' }}
                        orientation="horizontal"
                        size="small"
                        items={[
                            { id: '1', label: 'Organization Name' },
                            { id: '2', label: 'Provider Name' }
                        ]}
                        /* istanbul ignore next */
                        onChange={(e) => { e.id === '1' ? setIsOrganization(true) : setIsOrganization(false); }} />
                </Col>
            </Row>
            {!isOrganization && (
                <Row>
                    <Col sm={3}>
                        <Input
                            aria-label="providerFirstName"
                            dataTestId="test-providerFirstName"
                            domID="providerFirstName"
                            name="providerDetails.firstName"
                            label="First Name *"
                            size="medium"
                            placeholder="First Name"
                            errorMessage="Invalid First Name"
                            regex="^[^\t\n]{0,35}$"
                            value={eligibilityInquiryRequestBody?.providerDetails?.firstName}
                            onChange={handleInputChange} />
                    </Col>
                    <Col sm={3}>
                        <Input
                            aria-label="providerLastName"
                            dataTestId="test-providerLastName"
                            domID="providerLastName"
                            name="providerDetails.lastName"
                            label="Last Name *"
                            size="medium"
                            placeholder="Last Name"
                            errorMessage="Invalid Last Name"
                            regex="^[^\t\n]{0,60}$"
                            value={eligibilityInquiryRequestBody?.providerDetails?.lastName}
                            onChange={handleInputChange} />
                    </Col>
                </Row>
            )}
            {isOrganization && (
                <Row>
                    <Col sm={3}>
                        <Input
                            aria-label="organizationName"
                            dataTestId="test-organizationName"
                            domID="organizationName"
                            name="providerDetails.organizationName"
                            label="Organization Name *"
                            size="medium"
                            placeholder="Organization Name"
                            errorMessage="Invalid Organization Name"
                            regex="^[^\t\n]{0,60}$"
                            value={eligibilityInquiryRequestBody?.providerDetails?.organizationName}
                            onChange={handleInputChange} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm={3}>
                    <Text variant="xSmallBold" className="eligibility-inquiry-header">Enter NPI or Tax ID *</Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6} style ={{marginTop : '-10px'}}>
                    <RadioGroup
                        dataTestId="test-npiOrTaxId"
                        checkedItem={{ id: isNpi ? '1' : '2' }}
                        orientation="horizontal"
                        size="small"
                        items={[
                            { id: '1', label: 'NPI' },
                            { id: '2', label: 'Tax ID' }
                        ]}
                        /* istanbul ignore next */
                        onChange={(e) => { e.id === '1' ? setIsNpi(true) : setIsNpi(false); }} />
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    {isNpi && 
                      <Input
                        aria-label="providerNpi"
                        dataTestId="test-providerNpi"
                        domID="providerNpi"
                        name="providerDetails.npi"
                        label="NPI *"
                        placeholder="NPI"
                        size="medium"
                        maxLength={10}
                        value={eligibilityInquiryRequestBody?.providerDetails?.npi}
                        errorMessage="Invalid NPI"
                        regex="^[0-9]{10}$"
                        onChange={handleInputChange} />}
                    {!isNpi &&
                      <Input
                         aria-label="providerTaxId"
                         dataTestId="test-providerTaxId"
                         domID="providerTaxId"
                         name="providerDetails.taxId"
                         label="Tax ID *"
                         placeholder="Tax ID"
                         size="medium"
                         maxLength={9}
                         value={eligibilityInquiryRequestBody?.providerDetails?.taxId}
                         errorMessage="Invalid Tax ID"
                         regex="^[0-9]{9}$"
                         onChange={handleInputChange} />
                    }
                </Col>
                <Col sm={3}>
                    <Input
                        aria-label="providerServiceProviderNumber"
                        dataTestId="test-providerServiceProviderNumber"
                        domID="providerServiceProviderNumber"
                        name="providerDetails.serviceProviderNumber"
                        label="Service Provider Number"
                        size="medium"
                        placeholder="Service Provider Number"
                        value={eligibilityInquiryRequestBody?.providerDetails?.serviceProviderNumber}
                        onChange={handleInputChange} />
                </Col>
            </Row>
        </>
    )
};

export default ProviderDetailsComponent;