import { IUserPermission } from '../../api/user/interface';

export enum ResourceTypes {
  AttachmentSearch = 'AttachmentSearch',
  AttachmentDetails = 'AttachmentDetails',
  AttachmentFiles = 'AttachmentFiles',
  EraSearch = 'EraSearch',
  EligibilityInquiry = 'EligibilityInquiry',
  HistoricalEligibilitySearch = 'HistoricalEligibilitySearch',
  HistoricalEligibilityDetail = 'HistoricalEligibilityDetail',
  ProcessedClaimSearch = 'ProcessedClaimSearch',
  ProcessedClaimDetails = 'ProcessedClaimDetails',
  ProcessedClaimClearinghouseStatus = 'ProcessedClaimClearinghouseStatus',
  //TODO: US8033381: Remove Obsolete Components of Legacy Authorization
  //Had to create this to align with old actions even though this isn't something that exists in Guardian
  OnboardAccount = 'OnboardAccount',
}

export enum PermissionTypes {
  Read = 'Read',
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
}

export const permissionResourceTypes = {
  onboardAccount: {
    resourceType: ResourceTypes.OnboardAccount,
    resourcePermission: PermissionTypes.Create,
  },
  searchAttachments: {
    resourceType: ResourceTypes.AttachmentSearch,
    resourcePermission: PermissionTypes.Read,
  },
  readAttachmentDetails: {
    resourceType: ResourceTypes.AttachmentDetails,
    resourcePermission: PermissionTypes.Read,
  },
  readAttachmentFiles: {
    resourceType: ResourceTypes.AttachmentDetails,
    resourcePermission: PermissionTypes.Read,
  },
  searchEra: {
    resourceType: ResourceTypes.EraSearch,
    resourcePermission: PermissionTypes.Read,
  },
  submitEligibilityInquiry: {
    resourceType: ResourceTypes.EligibilityInquiry,
    resourcePermission: PermissionTypes.Create,
  },
  readEligibilityInquiry: {
    resourceType: ResourceTypes.EligibilityInquiry,
    resourcePermission: PermissionTypes.Read,
  },
  searchProcessedClaims: {
    resourceType: ResourceTypes.ProcessedClaimSearch,
    resourcePermission: PermissionTypes.Read,
  },
  readProcessedClaimDetails: {
    resourceType: ResourceTypes.ProcessedClaimDetails,
    resourcePermission: PermissionTypes.Read,
  },
  searchHistoricalEligibility: {
    resourceType: ResourceTypes.HistoricalEligibilitySearch,
    resourcePermission: PermissionTypes.Read,
  },
  readHistoricalEligibilityDetail: {
    resourceType: ResourceTypes.HistoricalEligibilityDetail,
    resourcePermission: PermissionTypes.Read,
  },
} as IPermissionResourceTypes;

export interface IInitialUserPermissionState {
  permissions: IUserPermission[] | undefined;
}

export interface IPermissionResourceType {
  resourceType: string;
  resourcePermission: string;
}
export interface IPermissionResourceTypes {
  readHistoricalEligibilityDetail: IPermissionResourceType;
  searchHistoricalEligibility: IPermissionResourceType;
  readProcessedClaimDetails: IPermissionResourceType;
  searchProcessedClaims: IPermissionResourceType;
  readEligibilityInquiry: IPermissionResourceType;
  submitEligibilityInquiry: IPermissionResourceType;
  onboardAccount: IPermissionResourceType;
  searchEra: IPermissionResourceType;
  searchAttachments: IPermissionResourceType;
  readAttachmentDetails: IPermissionResourceType;
  readAttachmentFiles: IPermissionResourceType;
}
