import axios from 'axios';
import { getToken } from '../utils/getToken';
import { REACT_APP_BACKEND_API } from '../config';

export const apiClient = axios.create({
  baseURL: `${REACT_APP_BACKEND_API}/dentalnetwork/portal/v1`,
});

apiClient.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  return config;
});
