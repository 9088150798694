//TODO: US8033381: Remove Obsolete Components of Legacy Authorization
export enum UserActionTypes {
  GETERAFILEDATA = 'GetEraFileData',
  SEARCHERADETAILS = 'SearchEraDetails',
  GETELIGIBILITYBYID = 'GetEligibilityById',
  SEARCHELIGIBILITY = 'SearchEligibility',
  GETELIGIBILITYFILEDATA = 'GetEligibilityFileData',
  SEARCHERA = 'SearchEra',
  CREATEUSER = 'CreateUser',
  GETCURRENTUSERSUMMARY = 'GetCurrentUserSummary',
  CREATEACCOUNT = 'CreateAccount',
  SEARCHPROCESSEDCLAIMS = 'SearchProcessedClaims',
  GETPROCESSEDCLAIMDETAIL = 'GetProcessedClaimDetails',
  ONBOARDACCOUNT = 'OnboardAccount',
  SUBMITELIGIBILITYINQUIRY = 'SubmitEligibilityInquiry',
}

//TODO: US8033381: Remove Obsolete Components of Legacy Authorization
export interface IUserAccountInfo {
  accountId: string;
  username: string;
  firstName: string;
  lastName: string;
  allowableActions: {
    actionName: UserActionTypes;
  }[];
  adminAccountInfo?: {
    adminAccountId: string;
    name: string;
  };
}

//TODO: US8033381: Remove Obsolete Components of Legacy Authorization
export interface IAdminAccountDetail {
  id?: string | undefined;
  adminAccountId?: string | undefined;
  name: string;
  phoneNumber: string;
  administratorName: string;
  administratorPhoneNumber: string;
  administratorEmail: string;
  customerId: string;
  primaryAddress: {
    id: string;
    addressTypeId: string;
    line1: string;
    line2: string;
    city: string;
    stateCode: string;
    zipCode: string;
    country: string;
  };
  updateToken?: string | undefined;
}

export interface IListUserPermissionResponse {
  userId: string;
  permissions: IUserPermission[];
}

export interface IUserPermission {
  resourceType: string;
  resourcePermissions: string[];
}
