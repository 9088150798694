import React, { useEffect, useState, memo, SyntheticEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Text, Button, Input, DateRangePicker } from '@uicl/ui-core/dist';
import './Claim.css';
import moment from 'moment';
import { IClaimViewResponse } from '../../../api/claimView/interface';
import { useAppDispatch } from '../../../reduxStore/reduxHooks';
import { handleClaimSearchApi } from '../../../api/claimSearch';
import { ESortingDirection, TSortingField } from './interface';
import {
  EClaimSearchDrawerMode,
  IClaimFilterRequestBody,
  IClaimSearch,
  IClaimSearchResponse
} from '../../../api/claimSearch/interface';
import { setAppError } from '../../../reduxStore/authSlice';
import { regex } from './regex';
import { handleDocumentBodyScroll } from '../../../utils/constants';
import { EClaimStatus } from './ClaimStatus';
import ClaimFilterModal, { claimStatus } from './ClaimFilterModal';
import TableData from './TableData';
import PagingControls from '../../../components/Paging/PagingControls';

const limit = 30;
const StyledDiv = styled.div`
  height: 100vh;
  padding: 1rem;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const defaultClaimFilterForm = {
  payerId: '',
  cpsCarrierId: '',
  claimId: '',
  db2Key: '',
  patientLastName: '',
  patientFirstName: '',
  submitterId: '',
  claimStatus: EClaimStatus.All,
  patientInsuredId: '',
  controlNumber: '',
  billingProviderNpi: '',
  renderingProviderNpi: '',
};

const defaultIntitalClaimFilterSelection = {
  dataTestId: 'select-claim-status',
  id: 'select-claim-status',
  label: 'Select Claim Status',
  value: EClaimStatus.All,
};

const Claims = () => {
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(moment(new Date()).subtract(6, 'days'));
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(moment(startDate).add(6, 'days'));
  const [selectedDate, setSelectedDate] = useState();
  const [hasError, setHasError] = useState(true);
  const [hasDateError, setHasDateError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAdditionalError, setHasAdditionalError] = useState(false);
  const [showDisplayMessage, setShowDisplayMessage] = useState('');

  const [openClaimDetail, setOpenClaimDetail] = React.useState(false);
  const [claimId, setClaimId] = useState<string>('');

  const [claimViewData, setClaimViewData] = React.useState<IClaimViewResponse>({} as IClaimViewResponse);
  const [legalEntityId, setLegalEntityId] = useState<string | null>(null);
  const [taxId, setTaxId] = useState<string | ''>('');
  const [claimFilterErrors, setClaimFilterErrors] = useState<Record<string, string>>({});

  const [searchResponse, setSearchResponse] = useState<IClaimSearchResponse | undefined>(undefined);
  const [sortingDirection, setSortingDirection] = useState<ESortingDirection>(ESortingDirection.DESCENDING);
  const [sortingFieldArray, setSortingFieldArray] = useState<TSortingField[]>([]);
  const [claimSearchData, setClaimSearchData] = useState<IClaimSearch[]>([]);
  const [selectedClaim, setSelectedClaim] = React.useState<IClaimSearch>();
  const [claimDrawerMode, setClaimDrawerMode] = React.useState<EClaimSearchDrawerMode>(
    EClaimSearchDrawerMode.VIEWCLAIM
  );
  const [isOpenClaimSearchFilter, setOpenClaimSearchFilter] = useState(false);
  const [claimFilterRequestBody, setClaimFilterRequestBody] = useState<IClaimFilterRequestBody>(defaultClaimFilterForm);
  const [initialClaimStatusOption, setInitialClaimStatusOption] = useState<{
    label: string;
    value: string;
    dataTestId: string;
    id: string;
  } | null>(defaultIntitalClaimFilterSelection);

  const dispatch = useAppDispatch();

  const onHandleClaimFilterOnChange = (e?: ChangeEvent<HTMLInputElement>, key?: string, value?: string) => {
    const copy = { ...claimFilterRequestBody };
    if (e) {
      const { name, value } = e.target;
      if (
        ['cpsCarrierId', 'billingProviderNpi', 'renderingProviderNpi', 'claimId', 'submitterId', 'db2Key'].includes(
          name
        )
      ) {
        if (!/^\d*$/.test(value)) return;
      }

      if (name == 'renderingProviderNpi') {
        copy[name] = value.toLocaleUpperCase();
      } else {
        copy[name] = value;
      }
    } else {
      copy[key!] = value!;
      const findValue = claimStatus.find((result) => result.value == value!);
      setInitialClaimStatusOption(findValue!);
    }

    setClaimFilterRequestBody(copy);
  };

  const getCurrentPage = (): number => {
    if (searchResponse?.pagingOptions?.offset && searchResponse?.pagingOptions?.limit)
      return Math.ceil(searchResponse.pagingOptions.offset / searchResponse.pagingOptions.limit);
    else return 0;
  };

  const getTotalPages = (): number => {
    if (searchResponse?.pagingOptions?.offset && searchResponse?.pagingOptions?.limit)
      return Math.ceil(searchResponse.totalCount / searchResponse.pagingOptions.limit);
    else return 0;
  };
  
  const onCloseSearchModal = () => {
    setOpenClaimSearchFilter(false);
  };

  const onHandleReset = () => {
    setClaimFilterRequestBody(defaultClaimFilterForm);
    setInitialClaimStatusOption(defaultIntitalClaimFilterSelection);
  };

  const onClickThrough = (e: SyntheticEvent, row: any) => {
    commonClickThrough(e, row, EClaimSearchDrawerMode.VIEWCLAIM);
  };

  const onAttachmentClickThrough = (e: SyntheticEvent, row: any) => {
    commonClickThrough(e, row, EClaimSearchDrawerMode.VIEWATTACHMENT);
  };

  const commonClickThrough = (
    e: SyntheticEvent,
    row: any,
    drawerMode: EClaimSearchDrawerMode = EClaimSearchDrawerMode.VIEWCLAIM
  ) => {
    e.stopPropagation();
    handleDocumentBodyScroll(false);
    setOpenClaimDetail(true);
    setSelectedClaim(row);
    setClaimDrawerMode(drawerMode);
  };

  const onCloseDrawer = () => {
    handleDocumentBodyScroll(true);
    setOpenClaimDetail(false);
  };

  const handleOnClaimSearch = async (offset: number) => {
    try {
      if (!hasError) {
        setHasAdditionalError(false);
        const requestBody = {
          legalEntityId: taxId ?? '',
          payerId: claimFilterRequestBody.payerId ?? '',
          cpsCarrierId: claimFilterRequestBody.cpsCarrierId ?? '',
          claimId: claimFilterRequestBody.claimId ?? '',
          db2Key: claimFilterRequestBody.db2Key ?? '',
          patientLastName: claimFilterRequestBody.patientLastName ?? '',
          patientFirstName: claimFilterRequestBody.patientFirstName ?? '',
          submitterId: claimFilterRequestBody.submitterId ?? '',
          claimStatus: claimFilterRequestBody.claimStatus ?? '',
          insuredId: claimFilterRequestBody.patientInsuredId ?? '',
          controlNumber: claimFilterRequestBody.controlNumber ?? '',
          billingProviderNpi: claimFilterRequestBody.billingProviderNpi ?? '',
          renderingProviderNpi: claimFilterRequestBody.renderingProviderNpi ?? '',
          dateRangeFilter: {
            field: 'ProcessedOn',
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
          },
          sorting: {
            direction: sortingDirection,
            fields: sortingFieldArray,
          },
          offset,
          limit,
        };

        if (
          taxId === '' &&
          claimFilterRequestBody.payerId === '' &&
          claimFilterRequestBody.cpsCarrierId === '' &&
          claimFilterRequestBody.claimId === '' &&
          claimFilterRequestBody.db2Key === '' &&
          claimFilterRequestBody.patientLastName === '' &&
          claimFilterRequestBody.patientFirstName === '' &&
          claimFilterRequestBody.submitterId === '' &&
          claimFilterRequestBody.patientInsuredId === '' &&
          claimFilterRequestBody.controlNumber === '' &&
          claimFilterRequestBody.billingProviderNpi === '' &&
          claimFilterRequestBody.renderingProviderNpi === '' &&
          claimFilterRequestBody.claimStatus == EClaimStatus.All
        ) {
          setHasAdditionalError(true);
          setShowDisplayMessage('Please enter additional search filters');
          return;
        }

        const response = await handleClaimSearchApi(requestBody, dispatch);
        const newMap = response.results.map((item, index) => {
          return {
            ...item,
            dateOfService: moment(item.dateOfService).format('MM/DD/YYYY'),
            displayProcessedOn: moment(item.processedOn).format('MM/DD/YYYY'),
            amount: `${item.amount}`,
            status: item.status,
          };
        });

        setClaimSearchData(newMap);
        setSearchResponse(response);
      }
    } catch (err) {
      const error = err as Error;
      //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  // only for disabling the search button
  useEffect(() => {
    if (taxId != '' && !taxId.match(regex.taxId)) {
      setHasError(true);
      return;
    } else if (!startDate || !endDate) {
      setHasError(true);
      setErrorMessage('Start and end date should be a max of 7 days');
      setHasDateError(true);
      return;
    } else if (endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      if (end.diff(start, 'days') > 6) {
        setHasError(true);
        setErrorMessage('Start and end date should be a max of 7 days');
        setHasDateError(true);
        return;
      }
    }

    setHasError(false);
    setHasDateError(false);
    setErrorMessage('');
  }, [taxId, startDate, endDate]);

  // technically this can still send an api call on the last page,
  // however the PagingControls component has logic to disable the button.
  const handlePageNext = () => {
    if (searchResponse?.pagingOptions?.offset)
      handleOnClaimSearch(searchResponse.pagingOptions.offset);
  };

  const handlePagePrevious = () => {
    if (searchResponse?.pagingOptions?.offset) {
      const currentPage = getCurrentPage();
      const previousOffset = (currentPage * limit) - (limit * 2);
      handleOnClaimSearch(previousOffset);
    }
  };

  const renderHeader = () => (
    <StyledBox>
      <div style={{ marginTop: 40 }}>
        <Text variant="large">Claim Search</Text>
      </div>
    </StyledBox>
  );

  const handleOnClickSearchFilters = () => {
    setOpenClaimSearchFilter(true);
    console.log(openClaimDetail);
  };

  const onHandleApplySearch = () => {
    setOpenClaimSearchFilter(false);
    handleOnClaimSearch(0);
  };

  const renderButtonSection = () => (
    <StyledBox>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 15, display: 'flex', marginTop: 10 }}>
          <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="taxId"
            domID="taxId"
            placeholder="Tax ID"
            label="Tax ID"
            size="medium"
            name="taxId"
            onChange={(e) => setTaxId(e.target.value)}
            maxLength={11}
            errorMessage="Invalid Tax ID"
            regex={regex.taxId}
            hasError={hasError}
          />
          <DateRangePicker
            dataTestId="test-dateRangePicker"
            domID="test-dateRangePicker"
            errorMessage={errorMessage}
            minimumNights={0}
            numberOfMonths={1}
            openDirection="down"
            showCalendarIcon
            initialStartDate={startDate}
            initialEndDate={endDate}
            size="medium"
            maxDate={moment()}
            label="Processed On"
            onStartInputChange={(date: any) => {
              //TODO https://jira.healthcareit.net/browse/DCP2-6995
              /* istanbul ignore next */
              setSelectedDate(date as any);
            }}
            onDatesChange={(date: any) => {
              setStartDate(moment(date.startDate ?? undefined));
              setEndDate(moment(date.endDate ?? undefined));
            }}
            hasError={hasDateError}
          />

          <div style={{ marginLeft: 5, marginTop: 20, display: 'flex' }}>
            <Button
              dataTestId="claim-search-filter-button"
              domID="claim-search-filter-button"
              name="Search Filters"
              buttonType="diminished"
              size="small"
              onClick={handleOnClickSearchFilters}
            />
            <Button
              buttonType="standard"
              dataTestId={'apply-search'}
              domID={'apply-arrow'}
              size="medium"
              type="button"
              name="Search"
              onClick={() => handleOnClaimSearch(0)}
              disabled={hasError}
            />
          </div>
        </div>
      </div>
      {!hasAdditionalError && (
        <div style={{ display: 'flex' }}>
          <PagingControls
            previousPageHandler={handlePagePrevious}
            nextPageHandler={handlePageNext}
            currentPage={getCurrentPage()}
            totalPages={getTotalPages()}
          />
        </div>
      )}
    </StyledBox>
  );

  const renderClaims = () => (
    <>
      {renderButtonSection()}
      <div>
        {hasAdditionalError && (
          <p
            itemID="pErrorMessage"
            style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
            data-testid="claimErrorMessage"
          >
            {showDisplayMessage}
          </p>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        {!hasAdditionalError && (
          <div>
            <TableData
              openClaimDetail={openClaimDetail}
              onClickThrough={onClickThrough}
              onCloseDrawer={onCloseDrawer}
              claimViewData={claimViewData}
              setSortingDirection={setSortingDirection}
              setSortingFieldArray={setSortingFieldArray}
              claimSearchData={claimSearchData}
              sortingFieldArray={sortingFieldArray}
              setClaimViewData={setClaimViewData}
              selectedClaim={selectedClaim}
              setSelectedClaim={setSelectedClaim}
              claimDrawerMode={claimDrawerMode}
              setClaimDrawerMode={setClaimDrawerMode}
              onAttachmentClickThrough={onAttachmentClickThrough}
            />
          </div>
        )}
        <ClaimFilterModal
          onCloseSearchModal={onCloseSearchModal}
          isOpen={isOpenClaimSearchFilter}
          onHandleReset={onHandleReset}
          onHandleApplySearch={onHandleApplySearch}
          claimFilterRequestBody={claimFilterRequestBody}
          onHandleClaimFilterOnChange={onHandleClaimFilterOnChange}
          initialClaimStatusOption={initialClaimStatusOption}
          claimFilterErrors={claimFilterErrors}
        />
      </div>
    </>
  );

  return (
    <StyledDiv data-testid="claimsPage-testid">
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100px' }} />
        <div style={{ width: '100%' }}>
          {renderHeader()}
          <hr />
          {renderClaims()}
        </div>
      </div>
    </StyledDiv>
  );
};

export default memo(Claims);
