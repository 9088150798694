import { ESortingDirectionTransactionsSearch, IHistoricalTransactionsSearchResponse } from './interfaces';
import moment from 'moment';

export const initialHistoricalSearchFrontBody = {
  limit: 0,
  offset: 0,
  transactionId: '',
  transactionTime: '',
  payerId: '',
  vendorIds: '',
  taxId: '',
  insuredId: '',
  patientLastName: '',
  patientFirstName: '',
  isErrorsOnly: false,
  sorting: {
    direction: ESortingDirectionTransactionsSearch.DESCENDING,
    fields: ['timeoftransaction'],
  },
  dateRangeFilter: {
    field: 'Date',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
};
export const initialHistoricalSearchBody = {
  limit: 0,
  offset: 0,
  currentTransactionDurationInHours: 0,
  filters: {
    transactionId: '',
    payerId: '',
    vendorIds: [],
    taxId: [],
    insuredId: '',
    patientLastName: '',
    patientFirstName: '',
    isErrorsOnly: false,
  },
  sorting: {
    direction: 'Ascending',
    fields: ['transactionId'],
  },
  dateRangeFilter: {
    field: 'TrxDate',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
};

export const mockHistoricalResponse: IHistoricalTransactionsSearchResponse = {
  filters: {
    field: '',
    startDate: '05092024',
    endDate: '05202024',
  },
  resultCount: 100,
  totalCount: 100,
  pagingOptions: {
    offset: 0,
    limit: 20,
    morePages: true,
    totalCount: 0,
    resultCount: 0,
  },
  results: [
    {
      transactionId: '1',
      patientLastName: 'test',
      patientFirstName: 'test1',
      insuredId: '3434',
      payerId: '10',
      payerName: 'madhavi1',
      errorCode: '100',
      categoryCode: '90',
      category: 'useful',
      hippaCode: '90',
      timeOfTransaction: '07/10/2014',
      vendorId: '3434',
      userName: '10AB',
      taxId: '345345',
      renderingNpi: '3463453',
      totalSeconds: '35s',
      payerSeconds: '20s',
      instance: 'prod ',
    },
  ],
};
