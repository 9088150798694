import authSlice from './authSlice';
import claimDetailSlice from './claimDetailSlice';
import attachmentSlice from './attachmentSlice';
import notificationSlice from './notificationSlice';
import attachmentImagesSlice from './attachmentImagesSlice';
import userPermissionsSlice from './userPermissionsSlice';

export default {
  authSlice,
  claimDetailSlice,
  attachmentSlice,
  notificationSlice,
  attachmentImagesSlice,
  userPermissionsSlice,
};
